import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrationComponent } from './administration.component';
import { UsualModule } from 'src/app/usual.module';
import { PrimengModule } from 'src/app/primeng.module';
import { AdminFeasibilityComponent } from './admin-feasibility/admin-feasibility.component';
import { ManageAdminFeasibilityComponent } from './admin-feasibility/manage-admin-feasibility/manage-admin-feasibility.component';

@NgModule({
    declarations: [
        AdministrationComponent,
        AdminFeasibilityComponent,
        ManageAdminFeasibilityComponent
    ],
    imports: [
        CommonModule,
        UsualModule,
        PrimengModule
    ],
    exports: [
        AdministrationComponent,
        AdminFeasibilityComponent,
        ManageAdminFeasibilityComponent
    ]
})
export class AdministrationModule { }
