import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { InsideDisableComponent } from './inside-disable/inside-disable.component';
import { LoadingComponent } from './loading/loading.component';
import { UsualModule } from '../usual.module';
import { JsonViewerComponent } from '../main/json-viewer/json-viewer.component';

@NgModule({
    declarations: [
        LoadingComponent,
        InsideDisableComponent,
        JsonViewerComponent
    ],
    imports: [
        CommonModule,
        NgxJsonViewerModule,
        UsualModule
    ],
    exports: [
        LoadingComponent,
        InsideDisableComponent,
        JsonViewerComponent
    ]
})
export class UtilsModule { }
