import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule} from '@ngx-translate/core';
import { PrimengModule } from './primeng.module';
import { ColumnFilterDirective } from 'src/app/shared/directives/p-columnFilter.directive';
import { TableDirective } from 'src/app/shared/directives/p-table.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({ declarations: [
    TableDirective,
    ColumnFilterDirective
],
exports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PrimengModule,
    TableDirective,
    ColumnFilterDirective,
], imports: [CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PrimengModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class UsualModule { }
