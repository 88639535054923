import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { User } from 'src/app/models/User';
import { LoginService } from 'src/app/login/login.service';
import { environment } from 'src/environments/environment';
import { RequestsFilterService } from '../my-requests/requests-filter.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ConfigurationService } from 'src/app/core/services/configuration.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false,
})
export class HeaderComponent implements OnInit {

    languages = [];
    selectedLanguage = sessionStorage['currentLangCode'] || 'es';

    currentUser: User;
    items: MenuItem[];
    public isOfflineMode = false;

    constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private loginService: LoginService,
		private translate: TranslateService,
		private requestFilterService: RequestsFilterService,
		private configurationService: ConfigurationService) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit(): void {
        this.getLanguages();
        this.setItems();
        this.isOfflineMode = environment.offlineMode;
    }

    setItems() {
        this.items = [
            {
                label: 'HEADER.MY_REQUESTS',
                escape: false,
                routerLink: '/home/my-requests',
                icon: 'account_tree'
            }];

        if (this.configurationService.getConfiguration().clientEnviroment == 3) {
            this.items.push({
                label: 'HEADER.ADMINISTRATION',
                escape: false,
                routerLink: '/home/administration',
                icon: 'settings'
            });
        }
    }

    logout() {
        this.requestFilterService.clearAllFiltersAndGlobalSearch();
        this.requestFilterService.resetSortField();
        this.loginService.logout();
        this.router.navigate(['login']).catch(error => {console.error(error);});
    }

    setLanguage() {
        sessionStorage['currentLangCode'] = this.selectedLanguage;
        this.translate.use(this.selectedLanguage);
    }

    getLanguages() {
        this.languages = [...this.translate.getLangs()];
    }

    checkRouteIsActive(item: string): boolean {
        return this.router.url.includes(item);
    }
}
