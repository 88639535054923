import { Component, OnInit } from '@angular/core';
import { AdminFeasibilityService } from './admin-feasibility.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';


@Component({
    selector: 'app-admin-feasibility',
    templateUrl: './admin-feasibility.component.html'
})
export class AdminFeasibilityComponent implements OnInit {

    constructor(
		public adminFeasibilityService: AdminFeasibilityService,
		public utilsService: UtilsService,
		private translateService: TranslateService,
		private confirmationService: ConfirmationService
    ) { }

    async ngOnInit() {
        await this.adminFeasibilityService.fetchFeasibilityRules();
    }

    createFeasibilityRule() {
        this.adminFeasibilityService.selectedRule = undefined;
        this.adminFeasibilityService.selectedRuleIndex = undefined;
        this.adminFeasibilityService.isSidebarVisible = true;
    }

    updateFeasibilityRule(rulePosition: number) {
        this.adminFeasibilityService.selectedRule = this.adminFeasibilityService.feasibilityRules[rulePosition];
        this.adminFeasibilityService.selectedRuleIndex = rulePosition;
        this.adminFeasibilityService.isSidebarVisible = true;
    }

    confrimDeleteFeasibilityRule(event: Event, rulePosition: number) {
        if (!event || !event.target) return;

        this.confirmationService.confirm({
            target: event.target,
            message: this.translateService.instant('ADMINISTRATION.FEASIBILITY.DELETE_CONFIRMATION'),
            acceptLabel: this.translateService.instant('BUTTON.YES'),
            rejectLabel: this.translateService.instant('BUTTON.NO'),
            accept: () => {
                this.adminFeasibilityService.deleteFeasibilityRule(rulePosition).catch(error => {console.error(error);});
            }
        });
    }

    translateOperator(operator: string): string {
        return this.translateService.instant(`ADMINISTRATION.FEASIBILITY.OPERATORS.${operator}`);
    }
}
