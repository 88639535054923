import { Directive, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { RowsPerPageDefault, RowsPerPageOptions } from '../constants/common.constants';
import { Subject, takeUntil } from 'rxjs';

@Directive({
    // global directive
    selector: 'p-table'
})
export class TableDirective implements OnChanges, OnDestroy {

	@Input() totalRecords: number;
	private destroy$ = new Subject<void>();

	constructor(private table: Table, private translateService: TranslateService){ }

	ngOnChanges(changes: SimpleChanges) {
	    if (changes['totalRecords'] && this.totalRecords !== undefined) {
	        this.applyTableConfig();
	    }
	    this.table.first = 0;
	}

	ngOnDestroy() {
	    this.destroy$.next();
	    this.destroy$.complete();
	}

	private applyTableConfig() {
	    if(this.totalRecords > 5){
	        this.table.paginator = true;
	        this.table.showCurrentPageReport = true;
	        this.table.currentPageReportTemplate = this.translateService.instant('TABLE.PAGE_REPORT');
	        this.table.rows = this.getRows();
	        this.table.rowsPerPageOptions = RowsPerPageOptions;
	        this.table.sortMode = 'single';
	        this.table.scrollable = true;
	        // this.table.styleClass = 'p-datatable-gridlines';
	        this.table.showLoader = false;
	        this.addSubscriptions();
	    }else{
	        this.table.paginator = false;
	    }
	}

	private addSubscriptions() {
	    this.table.onPage.pipe(takeUntil(this.destroy$)).subscribe((event) => {
	        localStorage.setItem('odCurrentTableRows', event.rows.toString());
	    });

	    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
	        this.table.currentPageReportTemplate = this.translateService.instant('TABLE.PAGE_REPORT');
	    });
	}

	private getRows(){
	    let rows = parseInt(localStorage.getItem('odCurrentTableRows'));
	    if (!rows) {
	        localStorage.setItem('odCurrentTableRows', RowsPerPageDefault.toString());
	        rows = RowsPerPageDefault;
	    }
	    return rows;
	}
}
