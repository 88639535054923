import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BelenderStatusCodes } from 'src/app/models/BelenderStatusCodes';
import { BelenderWebShocketMessage } from 'src/app/models/BelenderWebShocketMessage';
import { PhysicalPersonQueryResponse } from 'src/app/models/PhysicalPersonQueryResponse';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from '../my-requests/request.service';
import { NewRequestService } from './new-request.service';
import { environment } from 'src/environments/environment';
import { AvailableQueries, DocumentationPack } from 'src/app/models/User';
import { NewRequestStatus } from 'src/app/models/NewRequestStatus';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfigurationService } from 'src/app/core/services/configuration.service';

@Component({
    selector: 'app-new-request',
    standalone: false,
    templateUrl: './new-request.component.html',
    styleUrl: './new-request.component.scss',
})
export class NewRequestComponent implements OnInit {

    public physicalPersonQueryResponse: PhysicalPersonQueryResponse;

	@Input() public newRequestStatus: NewRequestStatus = { title: 'New request', step: 1 };
	@Input() public trustedConsentUrlBoxed: any;

	public isLoading = false;
	public formIsDisabled = false;

	public documentationPackValues: DocumentationPack[] = [];
	private waitingMessagesTranslationKeys = ['NEW_REQUEST.WAITING_MESSAGES.WAIT_1', 'NEW_REQUEST.WAITING_MESSAGES.WAIT_2', 'NEW_REQUEST.WAITING_MESSAGES.WAIT_3'];
	public waitingMessages = [];

	private invalidFields = [];
	private requiredFieldsMissing = false;

	public selectedRequestorType = '';
	public selectedRequestType = '';

	public availableQueries: AvailableQueries;

	@Input() public belenderStatus: {
		actualStatus: string;
		actualStatusMessage: string;
	} = { actualStatus: '', actualStatusMessage: '' };

	constructor(
		private api: ApiService,
		private router: Router,
		private translateService: TranslateService,
		private toastService: ToastService,
		public requestService: RequestService,
		public newRequestService: NewRequestService,
		private sanitizer: DomSanitizer,
		private configurationService: ConfigurationService
	) { }

	ngOnInit(): void {
	    window.addEventListener("message", this.receiveMessage, false);
	    this.waitingMessages = this.waitingMessagesTranslationKeys.map(key => this.translateService.instant(key));
	    this.documentationPackValues = this.configurationService.getConfiguration().documentationPacks;
	    this.availableQueries = this.configurationService.getConfiguration().availableQueries;
	    this.newRequestService.resetRequestorAndRequestTypes();
	}

	receiveMessage = (event: MessageEvent) => {
	    const messageFromBelender = event.data as BelenderWebShocketMessage;

	    if (messageFromBelender && messageFromBelender.type == "websocket") {
	        if (BelenderStatusCodes.includes(messageFromBelender.data.status_code)) {
	            // Assert form está deshabilitado
	            if (!this.formIsDisabled) this.formIsDisabled = true;

	            this.belenderStatus.actualStatus = messageFromBelender.data.status_code;
	            this.belenderStatus.actualStatusMessage = messageFromBelender.data.status_message;

	            if (this.belenderStatus.actualStatus === "consent_process_completed") {
	                this.toastService.showSuccess('TOAST.SUCCESS', 'NEW_REQUEST.CREATED', true);
	            }
	        }
	    }
	};

	detailButtonIsVisible() {
	    return this.belenderStatus.actualStatus === "consent_process_completed";
	};

	updateTrustedUrl(newUrl: string) {
	    this.trustedConsentUrlBoxed = this.sanitizer.bypassSecurityTrustResourceUrl(newUrl);
	}

	goToDetail() {
	    if (this.physicalPersonQueryResponse)
	        this.router.navigate([`/home/my-requests/request-detail/${this.physicalPersonQueryResponse.queryId}`]).catch(error => { console.error(error); });
	}

	goToStepOne() {
	    this.newRequestStatus = { title: 'New request', step: 1 };
	    this.newRequestService.resetSidebar();
	}

	goToStepTwo() {
	    if (this.selectedRequestorType != '' && this.selectedRequestType != '') {
	        this.newRequestStatus = { title: 'Request data', step: 2 };
	        this.newRequestService.setForm(this.selectedRequestorType, this.selectedRequestType);
	    }
	}

	//the validation is moved to the confirmSubmit method
	async onSubmit() {
	    this.formIsDisabled = true;

	    if (this.newRequestService.myForm.valid) {
	        try {
	            this.isLoading = true;
	            const _myFormCopy = { ...this.newRequestService.myForm.value };

	            if (this.newRequestService.myForm.get('birthDate')) {
	                // modifying the birthDate to the correct format
	                const _birthDate = new Date(this.newRequestService.myForm.value.birthDate);
	                const _formattedBirthDate = _birthDate.toISOString().slice(0, 10);
	                _myFormCopy.birthDate = _formattedBirthDate;
	            }

	            if (this.newRequestService.myForm.get('personIdExpirationDate')) {
	                // modifying the personIdExpirationDate to the correct format
	                const _personIdExpirationDate = new Date(this.newRequestService.myForm.value.personIdExpirationDate);
	                const _formattedPersonIdExpirationDate = _personIdExpirationDate.toISOString().slice(0, 10);
	                _myFormCopy.personIdExpirationDate = _formattedPersonIdExpirationDate;
	            }

	            _myFormCopy.belenderCircuit = this.newRequestService.selectedBelenderCircuit;
	            _myFormCopy.clientEnvironment = this.configurationService.getConfiguration().clientEnviroment;
	            _myFormCopy.sendLink = this.getSendLink();
	            this.physicalPersonQueryResponse = await this.api.post<PhysicalPersonQueryResponse>('requests/new-request', _myFormCopy);

	            if (!this.validateResponse(this.physicalPersonQueryResponse)) {
	                this.isLoading = false;
	                this.formIsDisabled = false;
	                return;
	            }

	            if (this.newRequestService.requestType !== 'Clave_Pin_SMS' && this.physicalPersonQueryResponse.consentUrlBoxed != null) {
	                this.updateTrustedUrl(this.physicalPersonQueryResponse.consentUrlBoxed);
	            }

	            this.isLoading = false;
	            if (environment.offlineMode || this.newRequestService.requestType === 'Clave_Pin_SMS') {
	                this.newRequestStatus = { title: 'Request summary', step: 4 };
	            } else
	                this.newRequestStatus = { title: 'Validate request', step: 3 };
	        } catch {
	            this.isLoading = false;
	            this.formIsDisabled = false;
	            this.toastService.showError('TOAST.ERROR', 'NEW_REQUEST.ERROR', true);
	        }
	    }
	    else {
	        this.formIsDisabled = false;
	        this.checkValidationErrors();
	    }
	}

	private getSendLink(): boolean{
	    if (this.newRequestService.requestType === 'Clave_Pin_SMS') {
	        return true;
	    }
	    return false;
	}

	checkValidationErrors(): void {
	    for (const key in this.newRequestService.myForm.controls) { // LOOP THROUGH ALL FORM CONTROLS
	        if (Object.prototype.hasOwnProperty.call(this.newRequestService.myForm.controls, key)) { // CHECK IF THE PROPERTY BELONGS TO THE OBJECT
	            const control = this.newRequestService.myForm.get(key);
	            if (control.invalid) {
	                this.checkFieldIsRequiredOrInvalid(control, key);
	            }
	        }
	    }
	    this.showValidationErrors();

	    this.invalidFields = [];
	    this.requiredFieldsMissing = false;
	}

	checkFieldIsRequiredOrInvalid(control: AbstractControl, key: string) {
	    if (control.errors['required']) {
	        if (!this.requiredFieldsMissing) this.requiredFieldsMissing = true; // SET THE FLAG TO TRUE ONLY ONCE
	        const formField = document.querySelector(`[formControlName="${key}"]`);
	        formField.classList.add('ng-dirty', 'ng-touched'); // ADD THE CLASSES TO SHOW THE ERROR
	    } else {
	        this.invalidFields.push(`NEW_REQUEST.INVALID_FORM.${key}`);  // ADD THE INVALID FIELD TO THE ARRAY
	    }
	}

	/**
	 * if a required field is not filled, it shows a toast with a message,
	 * otherwise it shows a toast with the invalid fields
	 */
	showValidationErrors() {
	    if (this.requiredFieldsMissing) {
	        this.toastService.showError('TOAST.INVALID', 'NEW_REQUEST.ALL_FIELDS_REQUIRED', false, null, 'newRequestErrors');
	    } else {
	        this.toastService.showError('TOAST.INVALID', 'NEW_REQUEST.INVALID_FORM_DETAIL', false, 5000, 'newRequestErrors', this.invalidFields);
	    }
	}

	goToLastStep() {
	    if (this.newRequestService.isFinishingOTP){
	        this.newRequestService.isFinishingOTP = false;
	        window.location.reload();
	    }
	    this.newRequestStatus = { title: 'Request summary', step: 4 };
	}


	validateResponse(physicalPersonQueryResponse: PhysicalPersonQueryResponse): boolean {
	    if (physicalPersonQueryResponse.statusCode == 400 || physicalPersonQueryResponse.queryId == null) {
	        this.toastService.showError(`Error ${physicalPersonQueryResponse.statusCode}`, `${physicalPersonQueryResponse?.formattedErrors}`, false, 10000);  //TODO: traudcir
	        return false;
	    }

	    return true;
	}

	isFieldInvalid(fieldName: string, errorName: string): boolean {
	    const field = this.newRequestService.myForm.get(fieldName);

	    if (field.hasError(errorName) && (field.dirty || field.touched)) {
	        return true;
	    } else {
	        return false;
	    }
	}

	getRequestValidationTranslation(){
	    let requestValidationTranslation = 'NEW_REQUEST.SUBTITLE_VALIDATION';
	    if (this.newRequestService.requestType === 'Clave_Pin') {
	        requestValidationTranslation += '_WIDGET';
	    }else if (this.newRequestService.requestType === 'Clave_Pin_SMS') {
	        requestValidationTranslation += '_SMS';
	    }
	    return this.translateService.instant(requestValidationTranslation);
	}

	getRequestValidationInfo(){
	    if (this.newRequestService.requestType === 'Clave_Pin_SMS') {
	        return this.translateService.instant('NEW_REQUEST.PENDING_SMS_VALIDATION');
	    }
	    return this.translateService.instant('NEW_REQUEST.VALIDATED');
	}
}
