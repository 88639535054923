import { Component, Input } from '@angular/core';
import { RequestsFilterService } from '../requests-filter.service';
import { RequestService } from '../request.service';
import { debounceTime } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-requests-active-filters',
    templateUrl: './requests-active-filters.component.html'
})
export class RequestsActiveFiltersComponent {

    public formKeys: string[];
    public formControls: Record<string, AbstractControl<any, any>>;
    public queryId = '';
    public personId = '';
    public firstName = '';
    public email = '';
    public phoneNumber = '';
	@Input() public table: Table;

	constructor(
		public requestsFilterService: RequestsFilterService,
		public requestService: RequestService) {
	    this.initializeFormControls();

	    this.requestsFilterService.filterHandler()
	        .pipe(debounceTime(300))
	        .subscribe(() => {
	            this.requestsFilterService.searchingByColumnFilter = true;
	            this.initializeFormControls();
	        });
	}

	initializeFormControls() {
	    this.formControls = this.requestsFilterService.form.controls;
	    this.formKeys = [];

	    for (const key in this.formControls) {
	        if (Object.prototype.hasOwnProperty.call(this.formControls, key)) {
	            this.formKeys.push(key);
	        }
	    }

	    this.queryId = this.requestsFilterService.form.controls['queryId'].value;
	    this.personId = this.requestsFilterService.form.controls['personId'].value;
	    this.firstName = this.requestsFilterService.form.controls['firstName'].value;
	    this.email = this.requestsFilterService.form.controls['email'].value;
	    this.phoneNumber = this.requestsFilterService.form.controls['phoneNumber'].value;
	}

	getFormControlObject(key: string): AbstractControl<any, any> {
	    return this.formControls[key];
	}

	isFilterActive(key: string): boolean {
	    const value = this.formControls[key].value;
	    return value != null && value !== '';
	}

	thereIsMoreThanOneFilterActive(): boolean {
	    return this.formKeys.filter(key => this.isFilterActive(key)).length >= 2;
	}

	clearFilter(key: string) {
	    if (this[`${key}`]) this[`${key}`] = ''; // this.queryId = ''; this.personId = ''; this.firstName = ''; this.email = ''; this.phoneNumber = '';
	    this.requestsFilterService.clearFilter(key);
	}

	clearAllFiltersAndGlobalSearch() {
	    this.queryId = '';
	    this.personId = '';
	    this.firstName = '';
	    this.email = '';
	    this.phoneNumber = '';
	    this.requestsFilterService.clearAllFiltersAndGlobalSearch();

	    // reset sort field of the table
	    this.requestsFilterService.resetSortField();
	    if (this.table) { this.table.reset(); }
	}
}