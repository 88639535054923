import { Component } from '@angular/core';
import { RequestDetailService } from '../request-detail.service';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/utils/utils.service';
import { RequestDetailDocumentsService } from './request-detail-documents.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-request-detail-documents',
    templateUrl: './request-detail-documents.component.html'
})
export class RequestDetailDocumentsComponent  {

    public readonly feasibilityKeys: string[] = [
	    "ImporteDeudaTotalCirculanteCIRBE",
	    "ImporteMoraCIRBE",
	    "ScoreOpenBanking",
	    "IngresosElegibles",
	    "GastosComprometidos",
	    "DescubiertosCuenta",
	    "IngresosRecurrentesTrimestrales",
	    "NumeroRecobrosUltimoAno",
	    "ImporteMedioGastosApuestas",
	    "CantidadMesesGastosApuestas",
	    "VariacionCaidaIngresos",
	    "NumeroPagosPorFinancierasMensualmente",
	    "SaldoMedioMinimoMensual6Meses",
	    "LimiteCreditoAsignar",
	    "Viable",
	    "Fecha",
	    "CalculoDelLimite"
    ];

    constructor(
		public requestDetailDocumentsService: RequestDetailDocumentsService,
		public requestDetailService: RequestDetailService,
		private router: Router,
		public utilsService: UtilsService,
		private translateService: TranslateService) { }

    newWindowWithJSONViewer(obj: object) {
	    localStorage.setItem('jsonViewerObject', JSON.stringify(obj));

	    // open the JSON viewer in a new tab.
	    const urlTree = this.router.createUrlTree(['json-viewer']);
	    const absoluteUrl = `${window.location.origin}${this.router.serializeUrl(urlTree)}`;

	    // open the new tab with the calculated URL
	    window.open(absoluteUrl, '_blank');
    }

    openPDF(name: string) {
	    const url = this.requestDetailService.request.documents[name];
	    if (url) {
	        window.open(url, '_blank');
	    }
    }

    downloadPDF(name: string) {
	    this.requestDetailService.saveDocument(this.requestDetailService.request.documents[name], name).catch(error => { console.error(error); });
    }

    openJSON(name: string) {
	    this.newWindowWithJSONViewer(this.requestDetailService.request.data[name]);
    }

    downloadJSON(name: string) {
	    this.requestDetailService.saveJSON(this.requestDetailService.request.data[name], name);
    }

    translateFeasibilityKey(key: string): string {
	    return this.translateService.instant(`FEASIBILITY_KEYS.${key}`);
    }

    isNumber(value: any): boolean {
	    return typeof value === 'number';
    }

    translateIfBoolean(value: any): string {
	    if (typeof value === 'boolean') {
	        return this.utilsService.translateBoolean(value);
	    } else {
	        return value;
	    }
    }
}

// pagination idea
//GET https://api.opendata.com/requests?requestId=123908asdncas&pagination=90&from=100&to=180
//GET https://api.opendata.com/requests?requestId=123908asdncas&pagination=90&from=180&to=340
