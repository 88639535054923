<p-toast key="newRequestErrors">
  <ng-template let-message pTemplate="message">
    <div>
      <h4> {{message.summary | translate}} </h4>
      <p> {{message.detail | translate}} </p>
      <ul>
        <li *ngFor="let data of message.data">
          <span class="material-symbols-outlined">warning</span>
          {{data | translate}}
        </li>
      </ul>
    </div>
  </ng-template>
</p-toast>
<p-confirmDialog />

<div class="request-steps-header">
  <ul>
    <li [ngClass]="{'current': newRequestStatus.step === 1, 'done': newRequestStatus.step > 1 }">
      <span>1</span>
      <p>{{ "NEW_REQUEST.REQUEST_BEGGINING" | translate}}</p>
    </li>
    <li [ngClass]="{'current': newRequestStatus.step === 2, 'done': newRequestStatus.step > 2 }">
      <span>2</span>
      <p>{{ "NEW_REQUEST.SUBTITLE_REQUEST" | translate }}</p>
    </li>
    <li [ngClass]="{'current': newRequestStatus.step === 3, 'done': newRequestStatus.step > 3 }">
      <span>3</span>
      <p>{{ getRequestValidationTranslation() }}</p>
    </li>
    <li [ngClass]="{'current': newRequestStatus.step === 4, 'done': newRequestStatus.step > 4 }">
      <span>4</span>
      <p>{{ "NEW_REQUEST.REQUEST_SUMMARY" | translate}}</p>
    </li>
  </ul>
</div>

<div *ngIf="newRequestStatus.step === 1">
  <div>
    <h3 class="form-title center">{{ "NEW_REQUEST.REQUESTOR_TYPE" | translate }}</h3>
    <div class="flex-around">
      <div *ngIf="this.availableQueries.requestors.physicalPerson" class="new-request-radio"
        [ngClass]="{'active': this.selectedRequestorType === 'Physical_Person'}">
        <p-radioButton name="requestor-type" label="{{ 'NEW_REQUEST.PHYSICAL_PERSON' | translate }}"
          value='Physical_Person' [(ngModel)]="selectedRequestorType" />
        <span class="material-symbols-outlined">check</span>
      </div>
      <div *ngIf="this.availableQueries.requestors.legalEntity" class="new-request-radio legal"
        [ngClass]="{'active': this.selectedRequestorType === 'Legal_Entity'}">
        <p-radioButton name="requestor-type" label="{{ 'NEW_REQUEST.LEGAL_ENTITY' | translate }}" value='Legal_Entity'
          [(ngModel)]="selectedRequestorType" disabled />

        <span class="material-symbols-outlined">check</span>
      </div>
    </div>

    <div class="MT50" [ngClass]="{'select': this.selectedRequestorType !== ''}">
      <h3 class="form-title center">{{ "NEW_REQUEST.REQUEST_TYPE" | translate }}</h3>
      <div class="type-request">
        <div *ngIf="this.availableQueries.requests.clavePin" class="new-request-radio clave"
          [ngClass]="{'active': this.selectedRequestType === 'Clave_Pin'}">
          <p-radioButton name="request-type" label="{{ 'NEW_REQUEST.CLAVE_PIN_WITH_WIDGET' | translate }}" value='Clave_Pin'
            [(ngModel)]="selectedRequestType" [pTooltip]="'NEW_REQUEST.CLAVE_PIN_INFO' | translate"
            tooltipPosition="top" [disabled]="this.selectedRequestorType === ''" />
          <span class="material-symbols-outlined">check</span>
        </div>
        <div *ngIf="this.availableQueries.requests.clavePinWithSMS" class="new-request-radio clave"
          [ngClass]="{'active': this.selectedRequestType === 'Clave_Pin_SMS'}">
          <p-radioButton name="request-type" label="{{ 'NEW_REQUEST.CLAVE_PIN' | translate }}" value='Clave_Pin_SMS'
            [(ngModel)]="selectedRequestType" [pTooltip]="'NEW_REQUEST.CLAVE_PIN_WITH_SMS_INFO' | translate"
            tooltipPosition="top" [disabled]="this.selectedRequestorType === ''" />
          <span class="material-symbols-outlined">check</span>
        </div>
        <div *ngIf="this.availableQueries.requests.sms" class="new-request-radio sms"
          [ngClass]="{'active': this.selectedRequestType === 'SMS'}">
          <p-radioButton name="request-type" label="{{ 'NEW_REQUEST.SMS' | translate }}" value='SMS'
            [(ngModel)]="selectedRequestType" [pTooltip]="'NEW_REQUEST.SMS_INFO' | translate" tooltipPosition="top"
            [disabled]="this.selectedRequestorType === ''" />
          <span class="material-symbols-outlined">check</span>
        </div>
        <div *ngIf="this.availableQueries.requests.videoId" class="new-request-radio customer"
          [ngClass]="{'active': this.selectedRequestType === 'Video_Id'}">
          <p-radioButton name="request-type" label="{{ 'NEW_REQUEST.VIDEO_ID' | translate }}" value='Video_Id'
            [(ngModel)]="selectedRequestType" [pTooltip]="'NEW_REQUEST.VIDEO_ID_INFO' | translate" tooltipPosition="top"
            [disabled]="this.selectedRequestorType === ''" />
          <span class="material-symbols-outlined">check</span>
        </div>
        <div *ngIf="this.availableQueries.requests.knownClient" class="new-request-radio video"
          [ngClass]="{'active': this.selectedRequestType === 'Know_Client'}">
          <p-radioButton name="request-type" label="{{ 'NEW_REQUEST.KNOWN_CLIENT' | translate }}" value='Know_Client'
            [(ngModel)]="selectedRequestType" [pTooltip]="'NEW_REQUEST.KNOWN_CLIENT_INFO' | translate"
            tooltipPosition="top" [disabled]="this.selectedRequestorType === ''" />
          <span class="material-symbols-outlined">check</span>
        </div>
      </div>
    </div>

  </div>
  <div class="flex-end MT100">
    <button class="filled-btn only-text-btn" (click)="goToStepTwo()"
      [disabled]="this.selectedRequestorType.length < 1 || this.selectedRequestType.length < 1">{{
      "NEW_REQUEST.BEGIN_REQUEST" |
      translate
      }}</button>
  </div>
</div>

<div *ngIf="newRequestStatus.step === 2">
  <app-loading [isLoading]="isLoading" [messages]="waitingMessages">
    <h3 class="form-title">{{ "NEW_REQUEST.REQUEST_DATA" | translate }}</h3>
    <app-inside-disable [isDisabled]="formIsDisabled">
      <form [formGroup]="newRequestService.myForm" (ngSubmit)="onSubmit()" disabled>

        <div class="grid">
          <div class="col-4" *ngIf="newRequestService.myForm.get('firstName')">
            <label for="_name">{{ "NEW_REQUEST.NAME" | translate }}</label>
            <input pInputText="_name" id="_name" formControlName="firstName" />
          </div>

          <div class="col-4" *ngIf="newRequestService.myForm.get('firstSurname')">
            <label for="_first-surame">{{ "NEW_REQUEST.FIRST_SURNAME" | translate }}</label>
            <input pInputText="surame" id="surame" formControlName="firstSurname" />
          </div>

          <div class="col-4" *ngIf="newRequestService.myForm.get('secondSurname')">
            <label for="_second-surname">{{ "NEW_REQUEST.SECOND_SURNAME" | translate }}</label>
            <input pInputText="_second-surname" id="_second-surname" formControlName="secondSurname" />
          </div>

          <div class="col-7" *ngIf="newRequestService.myForm.get('personId')">
            <label for="_documentNumber">{{ 'NEW_REQUEST.ID_NUMBER' | translate }}</label>
            <input pInputText id="_documentNumber" formControlName="personId" />
          </div>

          <div class="col-5" *ngIf="newRequestService.myForm.get('personIdExpirationDate')">
            <label for="_expirationDate">{{ 'NEW_REQUEST.ID_EXPIRATION_DATE' | translate }}</label>
            <p-calendar id="_expirationDate" formControlName="personIdExpirationDate" dateFormat="dd/mm/yy"
              [showIcon]="true" />
          </div>

          <div class="col-8" *ngIf="newRequestService.myForm.get('birthDate')">
            <label for="date">{{ 'NEW_REQUEST.BIRTH_DATE' | translate }}</label>
            <p-calendar id="date" formControlName="birthDate" dateFormat="dd/mm/yy" [showIcon]="true" />
          </div>

          <div class="col-5" *ngIf="newRequestService.myForm.get('phoneNumber')">
            <label for="_phone">{{ 'NEW_REQUEST.PHONE' | translate }}</label>
            <input pInputText="_phone" formControlName="phoneNumber" />
          </div>

          <div class="col-7" *ngIf="newRequestService.myForm.get('email')">
            <label for="_email">{{ 'NEW_REQUEST.EMAIL' | translate }}</label>
            <input pInputText="_email" formControlName="email" />
          </div>

          <div class="col-12" *ngIf="newRequestService.myForm.get('personType')">
            <label for="personType">{{ "NEW_REQUEST.LABORAL_REGIME.TITLE" | translate }}</label>
            <div class="flex align-items-center gap-3 mt-1">
              <!-- <div class="field-radiobutton">
                <p-radioButton name="personType" formControlName="personType" value="00"
                  label="{{ 'NEW_REQUEST.LABORAL_REGIME.EMPLOYEE' | translate }}" />
              </div> -->
              <div class="field-radiobutton">
                <p-radioButton id="personType" name="personType" formControlName="personType" value="01"
                  label="{{ 'NEW_REQUEST.LABORAL_REGIME.FREELANCER' | translate }}" />
              </div>
            </div>
          </div>
          <div class="col-5" *ngIf="newRequestService.myForm.get('documentationPack')">
            <label for="_documentationPack">{{ 'NEW_REQUEST.DOC_PACK' | translate }}</label>
            <p-dropdown id="_documentationPack" [options]="documentationPackValues" [optionValue]="'packName'"
              [optionLabel]="'packName'" placeholder="{{ 'FILTER.SELECT' | translate }}"
              formControlName="documentationPack">
              <ng-template let-option pTemplate="item">
                <div pTooltip="{{option.packDescription}}" tooltipPosition="right">
                  {{option.packName}}
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>


        <div class="buttons-row">
          <button class="outline-btn" (click)="goToStepOne()">{{ "NEW_REQUEST.BACK" | translate }}</button>
          <button class="filled-btn">{{ 'NEW_REQUEST.CREATE_REQUEST' | translate }}</button>
        </div>
      </form>
    </app-inside-disable>
  </app-loading>
</div>

<div *ngIf="newRequestStatus.step === 3">
  <div class="iframe-container">
    <app-inside-disable [isDisabled]="detailButtonIsVisible()">
      <!-- <h5>Estado del proceso de autorización: {{belenderStatus?.actualStatusMessage}}</h5> -->
      <!-- TODO: ¿deberíamos traducir también lo que devuelve belender?  -->
      <div style="text-align: center;" *ngIf="trustedConsentUrlBoxed">
        <iframe width="600px" height="610px" [src]="trustedConsentUrlBoxed" frameborder="0"></iframe>
      </div>
    </app-inside-disable>
  </div>

    <!-- <h3 class="form-title">{{ "NEW_REQUEST.VALIDATE" | translate }}</h3> -->
    <div class="buttons-row" *ngIf="detailButtonIsVisible()">
      <button class="filled-btn" (click)="goToLastStep()" [disabled]="!physicalPersonQueryResponse && !newRequestService.isFinishingOTP">{{
        "NEW_REQUEST.NEXT" | translate }}</button>
    </div>
</div>

<div *ngIf="newRequestStatus.step === 4">
  <h3 class="form-title">{{ "NEW_REQUEST.REQUEST_SUMMARY" | translate}}</h3>

  <div class="grid form-inline label-W170" *ngIf="this.newRequestService.myForm.get('firstName')">
    <div class="col-12 mb-1">
      <label for="_name">{{ "NEW_REQUEST.NAME" | translate}}</label>
      <div class="read-only"><span id="_name">{{ newRequestService.myForm.get("firstName").value }}</span></div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('firstSurname')">
      <label for="_first-surame">{{ "NEW_REQUEST.FIRST_SURNAME" | translate}}</label>
      <div class="read-only"><span id="_first-surname">{{ newRequestService.myForm.get("firstSurname").value }}</span>
      </div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('secondSurname')">
      <label for="_second-surname">{{ "NEW_REQUEST.SECOND_SURNAME" | translate}}</label>
      <div class="read-only"><span id="_second-surname">{{ newRequestService.myForm.get("secondSurname").value }}</span>
      </div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('personType')">
      <label for="_laboral_egime">{{ "NEW_REQUEST.LABORAL_REGIME.TITLE" | translate}}</label>
      <div class="read-only"><span id="_laboral_regime">{{
          requestService.getPersonTypeTitle(newRequestService.myForm.get("personType").value) | translate}}</span></div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('birthDate')">
      <label for="_birthDate">{{ "NEW_REQUEST.BIRTH_DATE" | translate}}</label>
      <div class="read-only"><span id="_birthDate">{{ newRequestService.myForm.get("birthDate").value |
          date:"dd/MM/yyyy"}}</span>
      </div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('email')">
      <label for="_email">{{ "NEW_REQUEST.EMAIL" | translate}}</label>
      <div class="read-only"><span id="_email">{{ newRequestService.myForm.get("email").value }}</span></div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('phoneNumber')">
      <label for="_phone">{{ "NEW_REQUEST.PHONE" | translate}}</label>
      <div class="read-only"><span id="_phone">{{ newRequestService.myForm.get("phoneNumber").value }}</span></div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('documentationPack')">
      <label for="_documentationPack">{{ "NEW_REQUEST.DOC_PACK" | translate}}</label>
      <div class="read-only"><span id="_documentationPack">{{ newRequestService.myForm.get("documentationPack").value
          }}</span>
      </div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('personId')">
      <label for="_documentNumber">{{ "NEW_REQUEST.ID_NUMBER" | translate}}</label>
      <div class="read-only"><span id="_documentNumber">{{ newRequestService.myForm.get("personId").value }}</span>
      </div>
    </div>

    <div class="col-12 mb-1" *ngIf="this.newRequestService.myForm.get('personIdExpirationDate')">
      <label for="_expirationDate">{{ "NEW_REQUEST.ID_EXPIRATION_DATE" | translate}}</label>
      <div class="read-only"><span id="_expirationDate">{{ newRequestService.myForm.get("personIdExpirationDate").value
          |
          date:"dd/MM/yyyy"}}</span></div>
    </div>
  </div>

  <div class="info-box">
    <span class="material-symbols-outlined"> check_circle </span>
    <p>{{ getRequestValidationInfo() }}</p>
  </div>

  <div class="buttons-row">
    <button class="filled-btn" (click)="goToDetail()">{{ "NEW_REQUEST.DETAILS" | translate }}</button>
  </div>
</div>
