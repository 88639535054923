import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminFeasibilityService } from '../admin-feasibility.service';
import { TranslateService } from '@ngx-translate/core';
import { OperatorEnum } from 'src/app/shared/constants/common.constants';

@Component({
    selector: 'app-manage-admin-feasibility',
    templateUrl: './manage-admin-feasibility.component.html'
})
export class ManageAdminFeasibilityComponent implements OnInit {

    feasibilityRuleFormGroup!: FormGroup;
    feasibilityRuleValues: string[] = [];
    operatorOptions = [];

    constructor(
		public adminFeasibilityService: AdminFeasibilityService,
		private translateService: TranslateService) { }

    ngOnInit(): void {
        this.prepareFormGroup();
        this.prepareOperatorOptions();
    }

    prepareOperatorOptions() {
        const operatorLabelPrefix = 'ADMINISTRATION.FEASIBILITY.OPERATORS.';

        this.operatorOptions = Object.keys(OperatorEnum).map(key => {
            const operatorKey = OperatorEnum[key as keyof typeof OperatorEnum];
            return {
                label: this.translateService.instant(`${operatorLabelPrefix}${operatorKey}`),
                value: operatorKey
            };
        });
    }

    prepareFormGroup() {
        const ruleConcept = this.adminFeasibilityService.selectedRule?.ruleConcept || undefined;
        const ruleDescription = this.adminFeasibilityService.selectedRule?.ruleDescription || undefined;
        const fieldName = this.adminFeasibilityService.selectedRule?.fieldName || undefined;
        const fieldName2 = this.adminFeasibilityService.selectedRule?.fieldName2 || undefined;
        const operator = this.adminFeasibilityService.selectedRule?.operator || OperatorEnum.Equals;
        const value = this.adminFeasibilityService.selectedRule?.value || undefined;
        const active = this.adminFeasibilityService.selectedRule?.active === false ? false : true;	// If active is false, set it to false, otherwise set it to true

        this.feasibilityRuleFormGroup = new FormGroup({
            ruleConcept: new FormControl(ruleConcept, [Validators.required]),
            ruleDescription: new FormControl(ruleDescription, [Validators.required]),
            fieldName: new FormControl(fieldName, [Validators.required]),
            fieldName2: new FormControl(fieldName2),
            operator: new FormControl(operator, [Validators.required]),
            value: new FormControl(value, [Validators.required]),
            active: new FormControl(active),
            translationLabel: new FormControl('test'),
        });
    }

    handleSaveFeasibilityRule() {
        if (this.feasibilityRuleFormGroup.invalid) {
            // TODO: toast if the form is invalid
            return;
        }
        if (this.adminFeasibilityService.selectedRule) {
            this.adminFeasibilityService.updateFeasibilityRules(this.feasibilityRuleFormGroup.value).catch(error => {console.error(error);});
        } else {
            this.adminFeasibilityService.createFeasibilityRule(this.feasibilityRuleFormGroup.value).catch(error => {console.error(error);});
        }
    }
}
