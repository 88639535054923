import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const authGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const currentUser = authenticationService.currentUserValue;

    if (currentUser) { return true; }
    router.navigate(['/login'], { queryParams: { returnUrl: state.url } }).catch(error => { console.error(error); });
    return false;
};
