export const BelenderStatusCodes: string[] = [
    "terms_and_conditions",
    "waiting_login_seguridad_social",
    "waiting_otp_seguridad_social",
    "saved_otp",
    "waiting_documents",
    "waiting_otp_aeat",
    "invalid_otp_aeat",
    "consent_process_completed",
    "saved_otp"
];