import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestDetailDocumentsComponent } from './request-detail-documents/request-detail-documents.component';
import { RequestDetailHeaderComponent } from './request-detail-header/request-detail-header.component';
import { RequestDetailComponent } from './request-detail.component';
import { UtilsModule } from 'src/app/utils/utils.module';
import { UsualModule } from 'src/app/usual.module';
import { NewRequestModule } from '../new-request/new-request.module';
import { RequestDetailFeasibilityComponent } from './request-detail-feasibility/request-detail-feasibility.component';

@NgModule({
    declarations: [
        RequestDetailComponent,
        RequestDetailHeaderComponent,
        RequestDetailDocumentsComponent,
        RequestDetailFeasibilityComponent
    ],
    imports: [
        CommonModule,
        UtilsModule,
        UsualModule,
        NewRequestModule
    ],
    exports: [
        RequestDetailComponent,
        RequestDetailHeaderComponent,
        RequestDetailDocumentsComponent,
        RequestDetailFeasibilityComponent
    ]
})
export class RequestDetailModule { }